/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Icon, Text } from "@aws-amplify/ui-react";
import Frame549 from "./Frame549";

export default function Frame552(props) {
  const { overrides, ...rest } = props;

  return (
    <Flex
      gap="39px"
      direction="row"
      alignItems="flex-end"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Frame552")}
    >
      <Flex
        gap="20px"
        direction="column"
        alignItems="center"
        shrink="0"
        height="413.31px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 550")}
      >
        <Flex
          gap="10px"
          direction="column"
          shrink="0"
          position="relative"
          border="4px SOLID rgba(255,204,4,1)"
          borderRadius="80px"
          padding="41px 37px 41px 37px"
          {...getOverrideProps(overrides, "Frame 545")}
        >
          <Icon
            width="64px"
            height="57.31px"
            viewBox={{ minX: 0, minY: 0, width: 64, height: 57.30706787109375 }}
            paths={[
              {
                d: "M57 1.90522L57 32.0879L64 49.1347L0 49.1347L7 32.0879L7 1.90522C7 0.852337 7.85 0 8.9 0L55.1 0C56.15 0 57 0.852337 57 1.90522ZM12.95 44.6223L51.1 44.6223C52.3 44.6223 53.25 43.6697 53.25 42.4664C53.25 41.2631 52.3 40.3105 51.1 40.3105L12.95 40.3105C11.75 40.3105 10.8 41.2631 10.8 42.4664C10.8 43.6697 11.75 44.6223 12.95 44.6223ZM10.85 27.5756L53.35 27.5756L53.35 4.51237L10.85 4.51237L10.85 27.5756ZM59.5 57.307L4.5 57.307C2 57.307 0 55.3015 0 52.7947L64 52.7947C64 55.3015 62 57.307 59.5 57.307Z",
                fill: "rgba(255,204,4,1)",
                fillRule: "evenodd",
              },
            ]}
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Union39712013")}
          >
            <Icon
              width="64px"
              height="49.13px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 64,
                height: 49.1346435546875,
              }}
              paths={[
                {
                  d: "M57 32.0879L57 1.90522C57 0.852337 56.15 0 55.1 0L8.9 0C7.85 0 7 0.852337 7 1.90522L7 32.0879L0 49.1347L64 49.1347L57 32.0879ZM51.1 44.6223L12.95 44.6223C11.75 44.6223 10.8 43.6697 10.8 42.4664C10.8 41.2631 11.75 40.3105 12.95 40.3105L51.1 40.3105C52.3 40.3105 53.25 41.2631 53.25 42.4664C53.25 43.6697 52.3 44.6223 51.1 44.6223ZM53.35 27.5756L10.85 27.5756L10.85 4.51237L53.35 4.51237L53.35 27.5756Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711997")}
            ></Icon>
            <Icon
              width="64px"
              height="4.51px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 64,
                height: 4.51239013671875,
              }}
              paths={[
                {
                  d: "M4.5 4.51237L59.5 4.51237C62 4.51237 64 2.50687 64 0L0 0C0 2.50687 2 4.51237 4.5 4.51237Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711998")}
            ></Icon>
          </Icon>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="286px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Title3909108")}
        >
          <Text
            fontFamily="Poppins"
            fontSize="24px"
            fontWeight="700"
            color="rgba(25,27,31,1)"
            lineHeight="32px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            width="286px"
            grow="1"
            basis="286px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Train GRC&#xA;Academy"
            {...getOverrideProps(overrides, "Train GRC Academy")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="374px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Title3909112")}
        >
          <Text
            fontFamily="Poppins"
            fontSize="18px"
            fontWeight="400"
            color="rgba(83,83,83,1)"
            lineHeight="32px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            width="374px"
            grow="1"
            basis="374px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Get Access to Individual Courses For Each Cloud Provider Featuring &#xA; Hands-On Labs at Train GRC Academy."
            {...getOverrideProps(
              overrides,
              "Get Access to Individual Courses For Each Cloud Provider Featuring Hands-On Labs at Train GRC Academy."
            )}
          ></Text>
        </Flex>
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(174,179,183,1)"
          borderRadius="5px"
          padding="8px 16px 8px 16px"
          size="large"
          isDisabled={false}
          variation="default"
          children="Sign Up"
          {...getOverrideProps(overrides, "Button50110193")}
        ></Button>
      </Flex>
      <Frame549
        display="flex"
        gap="20px"
        direction="column"
        alignItems="center"
        shrink="0"
        height="413px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 549")}
      ></Frame549>
      <Flex
        gap="20px"
        direction="column"
        alignItems="center"
        shrink="0"
        height="412.35px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 551")}
      >
        <Flex
          gap="10px"
          direction="column"
          shrink="0"
          position="relative"
          border="4px SOLID rgba(255,204,4,1)"
          borderRadius="80px"
          padding="38px 26px 38px 26px"
          {...getOverrideProps(overrides, "Frame 546")}
        >
          <Icon
            width="88px"
            height="62.35px"
            viewBox={{ minX: 0, minY: 0, width: 88, height: 62.34722900390625 }}
            paths={[
              {
                d: "M14.8057 14.6986C18.8535 14.6986 22.1349 11.4082 22.1349 7.34929C22.1349 3.29039 18.8535 0 14.8057 0C10.7579 0 7.47656 3.29039 7.47656 7.34929C7.47656 11.4082 10.7579 14.6986 14.8057 14.6986ZM25.7248 20.9284L21.5506 20.9284L14.8524 31.1493L8.20282 20.9284L3.88299 20.9284C1.74735 20.9284 0 22.6806 0 24.8221L0 39.7153C0 41.8568 1.74735 43.609 3.88299 43.609L6.26132 43.609L6.26132 59.8164C6.26132 61.2278 7.37769 62.3472 8.78527 62.3472L20.9196 62.3472C22.3272 62.3472 23.4436 61.2278 23.4436 59.8164L23.4436 43.5603L25.7248 43.5603C27.8605 43.5603 29.6078 41.8082 29.6078 39.6667L29.6078 24.8221C29.5593 22.6806 27.8605 20.9284 25.7248 20.9284ZM80.6212 7.34929C80.6212 11.4082 77.3398 14.6986 73.292 14.6986C69.2443 14.6986 65.9629 11.4082 65.9629 7.34929C65.9629 3.29039 69.2443 0 73.292 0C77.3398 0 80.6212 3.29039 80.6212 7.34929ZM84.2128 20.9284L80.0386 20.9284L73.3404 31.1493L66.6423 20.9284L62.3224 20.9284C60.1868 20.9284 58.4395 22.6806 58.4395 24.8221L58.4395 39.7153C58.4395 41.8568 60.1868 43.609 62.3224 43.609L64.7008 43.609L64.7008 59.8164C64.7008 61.2278 65.8171 62.3472 67.2247 62.3472L79.3591 62.3472C80.7667 62.3472 81.883 61.2278 81.883 59.8164L81.883 43.5603L84.1157 43.5603C86.2514 43.5603 87.9987 41.8082 87.9987 39.6667L87.9987 24.8221C88.0473 22.6806 86.3485 20.9284 84.2128 20.9284ZM50.9153 29.9325L49.1194 28.2777L50.4785 26.8663L54.7983 30.9059L50.4785 34.9456L49.2165 33.6802L49.0224 33.6315L50.9153 31.8794L38.0529 31.8794L39.7032 33.3882L38.2956 34.7509L34.1699 30.9059L38.2956 27.061L39.7032 28.4237L38.0529 29.9325L50.9153 29.9325Z",
                fill: "rgba(255,204,4,1)",
                fillRule: "evenodd",
              },
            ]}
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Union39712015")}
          >
            <Icon
              width="14.66px"
              height="14.7px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 14.658203125,
                height: 14.6986083984375,
              }}
              paths={[
                {
                  d: "M7.32915 14.6986C11.3769 14.6986 14.6583 11.4082 14.6583 7.34929C14.6583 3.29039 11.3769 0 7.32915 0C3.28137 0 0 3.29039 0 7.34929C0 11.4082 3.28137 14.6986 7.32915 14.6986Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711991")}
            ></Icon>
            <Icon
              width="29.61px"
              height="41.42px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 29.607421875,
                height: 41.4188232421875,
              }}
              paths={[
                {
                  d: "M25.7248 0L21.5506 0L14.8524 10.2209L8.20282 0L3.88299 0C1.74735 0 0 1.75215 0 3.89366L0 18.7869C0 20.9284 1.74735 22.6806 3.88299 22.6806L6.26132 22.6806L6.26132 38.888C6.26132 40.2994 7.37769 41.4188 8.78527 41.4188L20.9196 41.4188C22.3272 41.4188 23.4436 40.2994 23.4436 38.888L23.4436 22.6319L25.7248 22.6319C27.8605 22.6319 29.6078 20.8798 29.6078 18.7383L29.6078 3.89366C29.5593 1.75215 27.8605 0 25.7248 0Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711992")}
            ></Icon>
            <Icon
              width="14.66px"
              height="14.7px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 14.658203125,
                height: 14.6986083984375,
              }}
              paths={[
                {
                  d: "M7.32915 14.6986C11.3769 14.6986 14.6583 11.4082 14.6583 7.34929C14.6583 3.29039 11.3769 0 7.32915 0C3.28138 0 0 3.29039 0 7.34929C0 11.4082 3.28138 14.6986 7.32915 14.6986Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711993")}
            ></Icon>
            <Icon
              width="29.56px"
              height="41.42px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 29.560546875,
                height: 41.4188232421875,
              }}
              paths={[
                {
                  d: "M25.7734 0L21.5991 0L14.901 10.2209L8.20282 0L3.88299 0C1.74734 0 0 1.75215 0 3.89366L0 18.7869C0 20.9284 1.74734 22.6806 3.88299 22.6806L6.26133 22.6806L6.26133 38.888C6.26133 40.2994 7.37769 41.4188 8.78527 41.4188L20.9196 41.4188C22.3272 41.4188 23.4436 40.2994 23.4436 38.888L23.4436 22.6319L25.6763 22.6319C27.8119 22.6319 29.5593 20.8798 29.5593 18.7383L29.5593 3.89366C29.6078 1.75215 27.909 0 25.7734 0Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711994")}
            ></Icon>
            <Icon
              width="20.63px"
              height="8.08px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 20.62890625,
                height: 8.079345703125,
              }}
              paths={[
                {
                  d: "M14.9495 1.41145L16.7454 3.06626L3.88299 3.06626L5.53327 1.55747L4.12568 0.194682L0 4.03968L4.12568 7.88467L5.53327 6.52189L3.88299 5.01309L16.7454 5.01309L14.8524 6.76524L15.0466 6.81391L16.3086 8.07935L20.6284 4.03968L16.3086 0L14.9495 1.41145Z",
                  fill: "rgba(255,204,4,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="relative"
              {...getOverrideProps(overrides, "Vector39711995")}
            ></Icon>
          </Icon>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="340px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Title3909110")}
        >
          <Text
            fontFamily="Poppins"
            fontSize="24px"
            fontWeight="700"
            color="rgba(25,27,31,1)"
            lineHeight="32px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            width="336px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Advisory Services and One-on-One Coaching"
            {...getOverrideProps(
              overrides,
              "Advisory Services and One-on-One Coaching"
            )}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="374px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Title3909114")}
        >
          <Text
            fontFamily="Poppins"
            fontSize="18px"
            fontWeight="400"
            color="rgba(83,83,83,1)"
            lineHeight="32px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            width="374px"
            grow="1"
            basis="374px"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Our consultants can help your organization with audit support services, including cohort-based coaching"
            {...getOverrideProps(
              overrides,
              "Our consultants can help your organization with audit support services, including cohort-based coaching"
            )}
          ></Text>
        </Flex>
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(174,179,183,1)"
          borderRadius="5px"
          padding="8px 16px 8px 16px"
          size="large"
          isDisabled={false}
          variation="default"
          children="Schedule a Meeting"
          {...getOverrideProps(overrides, "Button50110155")}
        ></Button>
      </Flex>
    </Flex>
  );
}
