/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text } from "@aws-amplify/ui-react";
export default function Review(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="50px"
      direction="column"
      width="378px"
      position="relative"
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.15000000596046448)"
      borderRadius="8px"
      padding="30px 20px 30px 20px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Review")}
    >
      <Flex
        gap="10px"
        direction="row"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "text")}
      >
        <Text
          fontFamily="Poppins"
          fontSize="16px"
          fontWeight="400"
          color="rgba(25,27,31,1)"
          lineHeight="28px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="center"
          width="318px"
          grow="1"
          basis="318px"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="“ We liked the Train GRC course and our partnership. They provided a dedicated team to ‘hold our hand’ through the  whole period of training.”"
          {...getOverrideProps(
            overrides,
            "\u201C We liked the Train GRC course and our partnership. They provided a dedicated team to \u2018hold our hand\u2019 through the whole period of training.\u201D"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="22px"
        direction="row"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 29")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="61px"
          height="61px"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "image")}
        >
          <Icon
            width="61px"
            height="61px"
            viewBox={{ minX: 0, minY: 0, width: 61, height: 61 }}
            paths={[
              {
                d: "M61 30.5C61 47.3447 47.3447 61 30.5 61C13.6553 61 0 47.3447 0 30.5C0 13.6553 13.6553 0 30.5 0C47.3447 0 61 13.6553 61 30.5Z",
                fill: "rgba(196,196,196,1)",
                fillRule: "nonzero",
              },
            ]}
            position="absolute"
            top="0px"
            left="0px"
            {...getOverrideProps(overrides, "Ellipse 14")}
          ></Icon>
          <Image
            width="75.14px"
            height="113px"
            position="absolute"
            top="0px"
            left="-8px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(
              overrides,
              "jason-goodman-fznQW-kn5VU-unsplash 1"
            )}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="255px"
          justifyContent="center"
          grow="1"
          basis="255px"
          height="53px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "name details")}
        >
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="500"
            color="rgba(25,27,31,1)"
            lineHeight="55px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            height="21.5px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="James Cornway"
            {...getOverrideProps(overrides, "James Cornway")}
          ></Text>
          <Text
            fontFamily="Poppins"
            fontSize="12px"
            fontWeight="400"
            color="rgba(83,83,83,1)"
            lineHeight="18px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            height="21.5px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Senior director of programs &#xA;at BrandName"
            {...getOverrideProps(
              overrides,
              "Senior director of programs at BrandName"
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
