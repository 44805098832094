import { useState } from "react";
import { ReactComponent as WatchVideoIcon } from "../assets/svg/watchVideo.svg";

const YouTube = ({
  video,
  autoplay = false,
  rel,
  modest,
  height,
  tittle,
  styleContainer = {},
}) => {
  const [status, setStatus] = useState("idle");

  return (
    <div
      style={{
        width: "100%",
        height: height || "100%",
        backgroundSize: `500px !important`,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        borderRadius: "2rem",
        backgroundPosition: "center",
        alignItems: "center",
        ...styleContainer,
      }}
    >
      {status === "idle" ? (
        <div>
          <div style={{ display: "inline-block", cursor: "pointer" }}>
            <div
              onClick={(e) => setStatus("playing")}
              style={{
                height: height || "100%",
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <WatchVideoIcon />
              {/* 
            <button
              style={{
                borderRadius: "50%",
                width: "80px",
                height: "80px",
                cursor: "pointer",
                fontSize: "20px",
              }}
            >
              <WatchVideoIcon />
            </button> */}
            </div>
          </div>
          <div style={{ fontFamily: "Poppins" }}>Watch Video</div>
        </div>
      ) : (
        <iframe
        style={{borderRadius: '2rem'}}
          title={tittle}
          src={`https://www.youtube.com/embed/${video}?autoplay=${autoplay}&rel=${rel}&modestbranding=${modest}`}
          className="player"
          type="text/html"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay"
        />
      )}
    </div>
  );
};
export default YouTube;
