export const validateLinkedInProfileLink = (input) => {
  if (
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm.test(input)
  ) {
    return true;
  }
  return false;
};
export const validateLinkedInProfileLinkWithOutHttp = (input) => {
  if (/^linkedin\.com\/(pub|in|profile)/gm.test(input)) {
    return true;
  }
  return false;
};
// ^((http|https):\/\/)?+(www.linkedin.com\/)+[a-z]+(\/)+[a-zA-Z0-9-]{5,30}+
// ^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+
