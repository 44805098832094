import styles from "./styles.module.scss";
import PersonCard from "../card/PersonCard";
import PersonCard1Img from "../../assets/aboutUs/personCard1.png";
import PersonCard2Img from "../../assets/aboutUs/personCard2.png";

const CardBlock = () => {
  return (
    <div className={styles.cardBlock}>
      <PersonCard
        titleImg={PersonCard1Img}
        description={[
          <p>
            Wes has experience helping some of the largest and most heavily
            regulated organizations - including Fortune 100 Banking, Energy, and
            Healthcare clients.
          </p>,
          <p>
            Wes has served in a variety of capacities, from Internal Auditor to
            Penetration Tester to Cloud Security Architect. Through this
            experience, Wes gained a diverse array of skills and a passion for
            teaching.
          </p>,
        ]}
        name="Wes Ladd"
        post="Founder and Lead Instructor"
      />
      {/* <PersonCard
        titleImg={PersonCard2Img}
        description={[
          "Zach has award-winning videography and editing production experience - including creating, filming, and editing intros and commercials for daily news broadcast.",
          "Having served in every major video and audio production capacity, Zach ensures your organization looks and sounds contemporary and professional. More importantly, Zach’s creative direction can help bring your ideas to life.",
        ]}
        name="Zach Ladd"
        post="Founder and Executive Producer"
      /> */}
    </div>
  );
};
export default CardBlock;
