import React, { useContext, useRef } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrowLeft.svg";
import { ReactComponent as RightLeft } from "../../assets/svg/arrowRight.svg";
import { Review } from "../../ui-components";
import { useMedia } from "react-use";
import styles from "./styles.module.scss";

const CardBlock = () => {
  const isSmallMobile = useMedia("(max-width: 480px)", false);
  const listRef = useRef(null);

  const scroll = (scrollOffset) => {
    listRef.current.scrollLeft += scrollOffset;
  };

  return (
    <div className={styles.cardBlockContainer}>
      <div
        className={styles.arrowBtn}
        onClick={() => {
          // scroll(-390)
          scroll(-100);
        }}
      >
        <ArrowLeft />
      </div>

      <div className={styles.list} ref={listRef}>
        {Array(10)
          .fill(0)
          .map((item, index) => (
            <Review
              key={index}
              // width={isSmallMobile ? "70vw" : "378px"}
              className="review"
              overrides={{
                text: {
                  fontFamily: "Raleway",
                  width: "100%",
                  className: "review_content_text",
                  ".amplify-text": {
                    // width: "70vw",
                  },
                },
                "Frame 29": {
                  display: "flex",
                  flexDirection: "row",
                },
                "name details": {
                  className: "nameDetail",
                },
                "James Cornway": {
                  fontWeight: "bold",
                  className: "authorName",
                },
                image: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  className: "review_image",
                },
                "jason-goodman-fznQW-kn5VU-unsplash 1": {
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  position: "static",
                },
                "Ellipse 14": {
                  border: "1px solid #fff",
                },
              }}
            />
          ))}
      </div>
      <div
        className={styles.arrowBtn}
        onClick={() => {
          // scroll(390)
          scroll(100);
        }}
      >
        <RightLeft />
      </div>
    </div>
  );
  // return (
  //   <div className={styles.cardBlock}>
  //     <div className={styles.arrowBtn}>
  //       <ArrowLeft />
  //     </div>
  //     <div className={styles.list}>
  //       <CardItem
  //         description="We liked the Train GRC course and our partnership. They provided a dedicated team to ‘hold our hand’ through the  whole period of training."
  //         author="James Cornway"
  //         authorPost="Senior director of programs at BrandName"
  //         avatarSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOKgf1btqasr29ElrKVN80RPZxRDi4qTMnwQ&usqp=CAU"
  //       />
  //       <CardItem
  //         description="We liked the Train GRC course and our partnership. They provided a dedicated team to ‘hold our hand’ through the  whole period of training."
  //         author="James Cornway"
  //         authorPost="Senior director of programs at BrandName"
  //         avatarSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOKgf1btqasr29ElrKVN80RPZxRDi4qTMnwQ&usqp=CAU"
  //       />
  //       <CardItem
  //         description="We liked the Train GRC course and our partnership. They provided a dedicated team to ‘hold our hand’ through the  whole period of training."
  //         author="James Cornway"
  //         authorPost="Senior director of programs at BrandName"
  //         avatarSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOKgf1btqasr29ElrKVN80RPZxRDi4qTMnwQ&usqp=CAU"
  //       />
  //     </div>
  //     <div className={styles.arrowBtn}>
  //       <RightLeft />
  //     </div>
  //   </div>
  // );
};
export default CardBlock;
