import { Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./Pages/Home";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Signup from "./Pages/Signup";
import AboutUs from "./Pages/AboutUs";
import Solutions from "./Pages/Solutions";
// import Blog from "./Pages/Blog";
// import SpecificBlog from "./Pages/SpecificBlog";

function App() {
  return (
    <>
      <Router>
        <Route path="/" exact component={Home}></Route>
        <Route path="/about-us" component={AboutUs}></Route>
        <Route path="/signup" exact component={Signup}></Route>
        <Route path="/signup/containers" exact component={Signup}></Route>
        <Route path="/signup/dns" exact component={Signup}></Route>
        <Route path="/signup/mfa" exact component={Signup}></Route>
        <Route
          path="/signup/secure-credentials"
          exact
          component={Signup}
        ></Route>
        <Route
          path="/signup/phishing-mechanics"
          exact
          component={Signup}
        ></Route>
        <Route
          path="/signup/search-for-least-functionality"
          exact
          component={Signup}
        ></Route>
        <Route path="/solutions" component={Solutions}></Route>
        {/* <Route path="/blog" exact component={Blog}></Route>
        <Route path="/blog/:id" exact component={SpecificBlog}></Route> */}
      </Router>
    </>
  );
}

export default App;
